// extracted by mini-css-extract-plugin
export var outerContainer = "Product-module--outer-container--1HJVL";
export var section = "Product-module--section--2LhhB";
export var link = "Product-module--link--2wcXz";
export var categoryTitle = "Product-module--category-title--2TUPo";
export var title = "Product-module--title--3_hHH";
export var description = "Product-module--description--n9XoC";
export var figure = "Product-module--figure--1-ezf";
export var sticker = "Product-module--sticker--20ION";
export var hr = "Product-module--hr--2Xbxr";
export var bottomSection = "Product-module--bottom-section--328-Y";